.toggle {
	&_track {
		--size: var(--toggle-size, 34px);
		--track-width: calc(var(--size) / calc(34 / 60));
		--thumb-size: calc(var(--size) / calc(34 / 26));

		background-color: var(--foreground);
		border-radius: 999px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: var(--size);
		position: relative;
		user-select: none;
		width: var(--track-width);
		border: none;

		&_thumb {
			background-color: var(--background);
			border-radius: 50%;
			height: var(--thumb-size);
			width: var(--thumb-size);
			position: absolute;
			top: calc(var(--size) / 8.5);
			left: calc(var(--size) / 8.5);
			transform-origin: center;
			transition-property: transform;
			transition-duration: calc(var(--transition-duration) * 2);
			transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
			will-change: transform;

			&--toggled {
				transform: translateX(var(--thumb-size));
			}
		}

		&_sun,
		&_moon {
			font-size: calc(var(--size) * 0.45);
			width: 50%;
			display: grid;
			place-items: center;
			text-align: center;
			margin-block-start: calc(var(--size) * -0.1);
		}

		&_moon {
			padding-inline-end: calc(var(--size) * 0.2);
		}
	}
}
