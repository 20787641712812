.loader {
	--size: var(--loader-size, 1em);
	width: 100%;
	height: 100%;
	min-height: calc(var(--size) * 4);
	min-width: calc(var(--size) * 4);
	display: grid;
	place-items: center;
	background: var(--background);

	&-fullpage {
		position: fixed;
		width: 100vw;
		height: 100vh;
		inset: 0;
	}

	&_spinner {
		width: var(--size);
		height: var(--size);
		user-select: none;
		-webkit-user-drag: none;
		background-image: url('/spinner.svg');
		background-position: center;
		animation-name: spinner;
		animation-duration: 1000ms;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}

	@keyframes spinner {
		to {
			transform: rotate(360deg);
		}
	}
}
