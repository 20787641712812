.lazy-image {
	$this: &;

	&-wrapper {
		position: relative;
		overflow: hidden;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: 100%;
			max-height: 100%;
			min-width: 100%;
			min-height: 100%;

			&#{$this} {
				&-real {
					position: relative;
				}
			}
		}
	}

	&-preview {
		filter: blur(20px);
		transform: scale(1.1);

		opacity: 1;
		transition: opacity calc(var(--transition-duration) * 2) ease-in !important;
		transition-delay: calc(var(--transition-duration) * 0.5);
		will-change: opacity;
	}

	&-real {
		opacity: 0;
		transition: opacity calc(var(--transition-duration) * 2) ease-out !important;
		will-change: opacity;

		&.medium-zoom {
			&-image {
				transition: transform calc(var(--transition-duration) * 1.5) cubic-bezier(0.2, 0, 0.2, 1),
					opacity calc(var(--transition-duration) * 2) ease-out !important;
				will-change: opacity, transform;
			}
		}
	}

	&-loaded {
		#{$this} {
			&-preview {
				opacity: 0;
			}

			&-real {
				opacity: 1;
			}
		}
	}
}
