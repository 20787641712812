.medium-zoom {
	&-overlay {
		z-index: 300;
	}

	&-image {
		border-radius: 0;

		&--opened {
			z-index: 301;
			object-fit: cover;
			opacity: 1;
		}
	}
}
