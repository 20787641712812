.clock {
	background: #fff;
	border: 0.3rem solid #fff;
	border-radius: 0.5rem;
	display: inline-block;
	margin-bottom: 1em;

	&__hours,
	&__minutes,
	&__seconds {
		background: linear-gradient(to bottom, #26303b 50%, #2c3540 50%);
		display: inline-block;
		color: #fff;
		font-family: 'Nunito', sans-serif;
		font-size: 3rem;
		font-weight: 300;
		padding: 0.5rem 1rem;
		text-align: center;
		position: relative;
	}

	&__hours {
		border-right: 0.15rem solid #fff;
		border-radius: 0.5rem 0 0 0.5rem;
	}
	&__minutes {
		border-right: 0.15rem solid #fff;
	}
	&__seconds {
		border-radius: 0 0.5rem 0.5rem 0;
	}
	&__hourtime {
		font-size: 1rem;
		position: absolute;
		top: 2px;
		left: 8px;
	}
}
