@mixin varHSLA($variable) {
	$variable-h: var(#{$variable + '-h'});
	$variable-s: var(#{$variable + '-s'});
	$variable-l: var(#{$variable + '-l'});
	$variable-a: var(#{$variable + '-a'});
	$result-opacity: max(0, min(1, calc(#{$variable-a} * var(--transparency))));
	#{$variable}: hsla($variable-h $variable-s $variable-l / $result-opacity);
}

@mixin varHSLAextend($variable, $inherit) {
	$variable-h: var(#{$variable + '-h'}, var(#{$inherit + '-h'}));
	$variable-s: var(#{$variable + '-s'}, var(#{$inherit + '-s'}));
	$variable-l: var(#{$variable + '-l'}, var(#{$inherit + '-l'}));
	$variable-a: var(#{$variable + '-a'}, var(#{$inherit + '-a'}));
	$result-opacity: max(0, min(1, calc(#{$variable-a} * var(--transparency))));
	#{$variable}: hsl($variable-h $variable-s $variable-l / $result-opacity);
}
