.tsp {
	&-page {
		display: grid;
		place-items: center;
		width: 100%;
		min-height: 100vh;
		padding: 2em;
	}

	&-card {
		background: var(--background);
		padding: 1em;
		border-radius: var(--border-radius);
		border: 0.4rem solid currentColor;
		box-shadow: 0.8rem 0.8rem 0 currentColor;

		&_title {
			font-size: 2rem;
			margin-block-end: 1rem;
		}

		&_form {
			display: flex;
			flex-direction: column;

			&_label {
				font-weight: bold;
				font-size: 1.4rem;
			}

			&_input {
				position: relative;

				box-sizing: border-box;
				display: block;
				width: 100%;
				border: 0.4rem solid currentColor;
				padding: 0.5em;
				color: currentColor;
				background: transparent;
				border-radius: calc(var(--border-radius) / 2);
			}

			&_button {
				font-weight: bold;
				margin-block-start: 1rem;
				color: var(--background);
				background: var(--foreground);
				padding: 0.5em;
				border: 0.4rem solid var(--foreground);
				border-radius: calc(var(--border-radius) / 2);
			}
		}
	}
}
