@font-face {
	font-family: 'anticon';
	src: url('//at.alicdn.com/t/font_1434092639_4910953.eot');
	/* IE9*/
	src: url('//at.alicdn.com/t/font_1434092639_4910953.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('//at.alicdn.com/t/font_1434092639_4910953.woff') format('woff'),
		/* chrome、firefox */ url('//at.alicdn.com/t/font_1434092639_4910953.ttf') format('truetype'),
		/* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('//at.alicdn.com/t/font_1434092639_4910953.svg#iconfont')
			format('svg');
	/* iOS 4.1- */
}

.rc-dropdown {
	$this: &;

	position: absolute;
	left: -9999px;
	top: -9999px;
	z-index: 299;
	display: block;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 1.5;
	width: 125px;

	&-hidden {
		display: none;
	}

	&-menu {
		outline: none;
		position: relative;
		list-style-type: none;
		padding: 0;
		margin: 2px 0 2px;
		text-align: left;
		background-color: #fff;
		border-radius: 3px;
		box-shadow: 0 1px 5px #ccc;
		background-clip: padding-box;
		border: 1px solid #ccc;

		&::before {
			content: '';
			position: absolute;
			top: -4px;
			left: 0;
			width: 100%;
			height: 4px;
			background: #ffffff;
			background: rgba(255, 255, 255, 0.01);
		}

		&-item {
			position: relative;
			display: block;
			padding: 7px 26px 7px 10px;
			clear: both;
			font-size: 12px;
			font-weight: normal;
			color: #666666;
			white-space: nowrap;
			cursor: pointer;

			> * {
				display: flex;
				align-items: center;

				> svg {
					padding: 5px;
					max-width: 24px;
					margin-inline-end: 2px;
				}
			}

			&:hover,
			&-active,
			&-selected {
				background-color: #ebfaff;
			}

			&-selected {
				position: relative;

				&::after {
					content: '\e613';
					font-family: 'anticon';
					font-weight: bold;
					position: absolute;
					color: #3cb8f0;
					top: 9.5px;
					right: 8px;
				}
			}

			&-disabled {
				color: #ccc;
				cursor: not-allowed;
				pointer-events: none;

				&:hover {
					color: #ccc;
					background-color: #fff;
					cursor: not-allowed;
				}
			}

			&:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}

			&:last-child {
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
			}

			&-divider {
				height: 1px;
				margin: 1px 0;
				overflow: hidden;
				background-color: #e5e5e5;
				line-height: 0;
			}
		}
	}

	&-slide-up-enter,
	&-slide-up-appear {
		animation-duration: 0.3s;
		animation-fill-mode: both;
		transform-origin: 0 0;
		display: block !important;
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
		animation-play-state: paused;
	}

	&-slide-up-leave {
		animation-duration: 0.3s;
		animation-fill-mode: both;
		transform-origin: 0 0;
		display: block !important;
		opacity: 1;
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
		animation-play-state: paused;
	}

	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-bottomLeft,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-bottomLeft,
	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-bottomCenter,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-bottomCenter,
	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-bottomRight,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-bottomRight {
		animation-name: slideUpIn;
		animation-play-state: running;
	}
	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-topLeft,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-topLeft,
	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-topCenter,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-topCenter,
	&-slide-up-enter#{$this}-slide-up-enter-active#{$this}-placement-topRight,
	&-slide-up-appear#{$this}-slide-up-appear-active#{$this}-placement-topRight {
		animation-name: slideDownIn;
		animation-play-state: running;
	}
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-bottomLeft,
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-bottomCenter,
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-bottomRight {
		animation-name: slideUpOut;
		animation-play-state: running;
	}
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-topLeft,
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-topCenter,
	&-slide-up-leave#{$this}-slide-up-leave-active#{$this}-placement-topRight {
		animation-name: slideDownOut;
		animation-play-state: running;
	}

	&-arrow {
		position: absolute;
		border-width: 4px;
		border-color: transparent;
		box-shadow: 0 1px 5px #ccc;
		border-style: solid;
		transform: rotate(45deg);
	}

	&-show-arrow#{$this}-placement-top,
	&-show-arrow#{$this}-placement-topLeft,
	&-show-arrow#{$this}-placement-topRight {
		padding-bottom: 6px;
	}
	&-show-arrow#{$this}-placement-bottom,
	&-show-arrow#{$this}-placement-bottomLeft,
	&-show-arrow#{$this}-placement-bottomRight {
		padding-top: 6px;
	}
	&-placement-top &-arrow,
	&-placement-topLeft &-arrow,
	&-placement-topRight &-arrow {
		bottom: 4px;
		border-top-color: white;
	}
	&-placement-top &-arrow {
		left: 50%;
	}
	&-placement-topLeft &-arrow {
		left: 15%;
	}
	&-placement-topRight &-arrow {
		right: 15%;
	}
	&-placement-bottom &-arrow,
	&-placement-bottomLeft &-arrow,
	&-placement-bottomRight &-arrow {
		top: 4px;
		border-bottom-color: white;
	}
	&-placement-bottom &-arrow {
		left: 50%;
	}
	&-placement-bottomLeft &-arrow {
		left: 15%;
	}
	&-placement-bottomRight &-arrow {
		right: 15%;
	}

	@keyframes slideUpIn {
		0% {
			opacity: 0;
			transform-origin: 0% 0%;
			transform: scaleY(0);
		}
		100% {
			opacity: 1;
			transform-origin: 0% 0%;
			transform: scaleY(1);
		}
	}
	@keyframes slideUpOut {
		0% {
			opacity: 1;
			transform-origin: 0% 0%;
			transform: scaleY(1);
		}
		100% {
			opacity: 0;
			transform-origin: 0% 0%;
			transform: scaleY(0);
		}
	}
	@keyframes slideDownIn {
		0% {
			opacity: 0;
			transform-origin: 0% 100%;
			transform: scaleY(0);
		}
		100% {
			opacity: 1;
			transform-origin: 0% 100%;
			transform: scaleY(1);
		}
	}
	@keyframes slideDownOut {
		0% {
			opacity: 1;
			transform-origin: 0% 100%;
			transform: scaleY(1);
		}
		100% {
			opacity: 0;
			transform-origin: 0% 100%;
			transform: scaleY(0);
		}
	}
}
