@import 'mixins';

// TODO: Refactor CSS variables to use varHSLA and H-S-L vars

:root {
	// Main colors
	--foreground-h: 45deg;
	--foreground-s: 8%;
	--foreground-l: 20%;
	--foreground-a: 1;
	@include varHSLA(--foreground);

	--background-h: 0deg;
	--background-s: 0%;
	--background-l: 100%;
	--background-a: 1;
	@include varHSLA(--background);

	--background-light-h: 0deg;
	--background-light-s: 0%;
	--background-light-l: 86%;
	--background-light-a: 1;
	@include varHSLA(--background-light);

	--background-lighter-h: 0deg;
	--background-lighter-s: 0%;
	--background-lighter-l: 72%;
	--background-lighter-a: 1;
	@include varHSLA(--background-lighter);

	--gray-foreground-h: 45deg;
	--gray-foreground-s: 2%;
	--gray-foreground-l: 60%;
	--gray-foreground-a: 1;
	@include varHSLA(--gray-foreground);

	--brown-foreground-h: 19deg;
	--brown-foreground-s: 27%;
	--brown-foreground-l: 31%;
	--brown-foreground-a: 1;
	@include varHSLA(--brown-foreground);

	--orange-foreground-h: 30deg;
	--orange-foreground-s: 89%;
	--orange-foreground-l: 45%;
	--orange-foreground-a: 1;
	@include varHSLA(--orange-foreground);

	--yellow-foreground-h: 46deg;
	--yellow-foreground-s: 99%;
	--yellow-foreground-l: 44%;
	--yellow-foreground-a: 1;
	@include varHSLA(--yellow-foreground);

	--green-foreground-h: 172deg;
	--green-foreground-s: 78%;
	--green-foreground-l: 27%;
	--green-foreground-a: 1;
	@include varHSLA(--green-foreground);

	--blue-foreground-h: 198deg;
	--blue-foreground-s: 87%;
	--blue-foreground-l: 32%;
	--blue-foreground-a: 1;
	@include varHSLA(--blue-foreground);

	--purple-foreground-h: 264deg;
	--purple-foreground-s: 44%;
	--purple-foreground-l: 45%;
	--purple-foreground-a: 1;
	@include varHSLA(--purple-foreground);

	--pink-foreground-h: 324deg;
	--pink-foreground-s: 74%;
	--pink-foreground-l: 39%;
	--pink-foreground-a: 1;
	@include varHSLA(--pink-foreground);

	--red-foreground-h: 0deg;
	--red-foreground-s: 72%;
	--red-foreground-l: 56%;
	--red-foreground-a: 1;
	@include varHSLA(--red-foreground);

	// Component colors
	--divider-a: 0.09;
	@include varHSLAextend(--divider, --foreground);

	--table-divider-a: 0.15;
	@include varHSLAextend(--table-divider, --foreground);

	--background-inline-code-h: 44deg;
	--background-inline-code-s: 6%;
	--background-inline-code-l: 50%;
	--background-inline-code-a: 0.15;
	@include varHSLA(--background-inline-code);

	--background-callout-h: 169deg;
	--background-callout-s: 31%;
	--background-callout-l: 90%;
	--background-callout-l: 0.3;
	@include varHSLA(--background-callout);

	--background-header-h: 0deg;
	--background-header-s: 0%;
	--background-header-l: 100%;
	--background-header-a: 0.8;
	@include varHSLA(--background-header);

	// Component properties
	--border-radius: 8px;
	--container-max-width: 829px;
	--header-height: 45px;
	--header-box-shadow: none;
	--toggle-size: 26px;

	// Motion
	--transition-duration: 200ms;

	// Transparency
	--transparency: 1;

	// Font
	--font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Apple Color Emoji', 'Arial', sans-serif,
		'Segoe UI Emoji', 'Segoe UI Symbol';
	--font-family-serif: 'Georgia', 'Times New Roman', 'Apple Color Emoji', serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
	--font-family-mono: 'Monaco', 'Courier', 'Apple Color Emoji', monospace, 'Segoe UI Emoji', 'Segoe UI Symbol';

	// Old
	--foreground-1: hsla(45, 8%, 20%, 0.16);
	--foreground-2: hsla(45, 8%, 20%, 0.4);
	--foreground-3: hsla(45, 8%, 20%, 0.6);
	--foreground-4: hsla(0, 0%, 0%, 1);
	--foreground-5: hsla(45, 8%, 20%, 0.024);
	--foreground-6: hsla(45, 8%, 20%, 0.8);

	--select-color-0: hsla(197, 71%, 52%, 1);
	--select-color-1: hsla(197, 71%, 52%, 0.3);
	--select-color-2: hsla(197, 70%, 91%, 1);

	--red_background: hsla(0, 74%, 94%, 1);
	--pink_background: hsla(326, 49%, 92%, 1);
	--blue_background: hsla(198, 42%, 91%, 1);
	--purple_background: hsla(266, 35%, 92%, 1);
	--green_background: hsla(169, 31%, 90%, 1);
	--yellow_background: hsla(45, 80%, 92%, 1);
	--orange_background: hsla(29, 74%, 92%, 1);
	--brown_background: hsla(20, 12%, 90%, 1);
	--gray_background: hsla(210, 5%, 93%, 1);

	--red_background_co: hsla(0, 74%, 94%, 0.3);
	--pink_background_co: hsla(326, 49%, 92%, 0.3);
	--blue_background_co: hsla(198, 42%, 91%, 0.3);
	--purple_background_co: hsla(266, 35%, 92%, 0.3);
	--green_background_co: hsla(169, 31%, 90%, 0.3);
	--yellow_background_co: hsla(45, 80%, 92%, 0.3);
	--orange_background_co: hsla(29, 74%, 92%, 0.3);
	--brown_background_co: hsla(20, 12%, 90%, 0.3);
	--gray_background_co: hsla(210, 5%, 93%, 0.3);

	--notion-item-blue: hsla(208, 100%, 44%, 0.2);
	--notion-item-orange: hsla(23, 100%, 48%, 0.2);
	--notion-item-green: hsla(168, 100%, 26%, 0.2);
	--notion-item-pink: hsla(325, 100%, 43%, 0.2);
	--notion-item-brown: hsla(20, 100%, 27%, 0.2);
	--notion-item-red: hsla(354, 100%, 50%, 0.2);
	--notion-item-yellow: hsla(43, 100%, 46%, 0.2);
	--notion-item-default: hsla(45, 4%, 80%, 0.5);
	--notion-item-purple: hsla(262, 74%, 51%, 0.2);
	--notion-item-gray: hsla(45, 2%, 60%, 0.4);
}

.dark-mode {
	// Main colors
	--foreground-h: 0deg;
	--foreground-s: 0%;
	--foreground-l: 100%;

	--background-l: 7%;

	--background-light-l: 21%;

	--background-lighter-l: 35%;

	--gray-foreground-h: 195deg;

	--brown-foreground-h: 18deg;
	--brown-foreground-s: 19%;
	--brown-foreground-l: 48%;

	--orange-foreground-s: 100%;
	--orange-foreground-l: 63%;

	--yellow-foreground-h: 48deg;
	--yellow-foreground-s: 100%;
	--yellow-foreground-l: 64%;

	--green-foreground-h: 169deg;
	--green-foreground-s: 38%;
	--green-foreground-l: 49%;

	--blue-foreground-h: 203deg;
	--blue-foreground-s: 53%;
	--blue-foreground-l: 56%;

	--purple-foreground-h: 265deg;
	--purple-foreground-s: 57%;
	--purple-foreground-l: 64%;

	--pink-foreground-h: 328deg;
	--pink-foreground-s: 71%;
	--pink-foreground-l: 61%;

	--red-foreground-h: 4deg;
	--red-foreground-s: 100%;
	--red-foreground-l: 71%;

	// Component colors
	--background-callout-h: 177deg;
	--background-callout-s: 18%;
	--background-callout-l: 25%;

	--background-header: transparent;

	--header-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);

	// Old

	--foreground-1: var(--foreground);
	--foreground-2: var(--foreground);
	--foreground-3: var(--foreground);
	--foreground-4: var(--foreground);
	--foreground-5: hsla(0, 0%, 100%, 0.6);
	--foreground-6: hsla(0, 0%, 100%, 0.87);

	--red_background: hsla(0, 16%, 30%, 1);
	--pink_background: hsla(318, 17%, 28%, 1);
	--blue_background: hsla(202, 22%, 27%, 1);
	--purple_background: hsla(252, 16%, 29%, 1);
	--green_background: hsla(177, 18%, 25%, 1);
	--yellow_background: hsla(54, 20%, 29%, 1);
	--orange_background: hsla(31, 21%, 29%, 1);
	--brown_background: hsla(0, 2%, 26%, 1);
	--gray_background: hsla(200, 6%, 29%, 1);
	--red_background_co: hsla(0, 16%, 30%, 0.3);
	--pink_background_co: hsla(318, 17%, 28%, 0.3);
	--blue_background_co: hsla(202, 33%, 60%, 0.3);
	--purple_background_co: hsla(252, 16%, 29%, 0.3);
	--green_background_co: hsla(177, 18%, 25%, 0.3);
	--yellow_background_co: hsla(54, 20%, 29%, 0.3);
	--orange_background_co: hsla(31, 21%, 29%, 0.3);
	--brown_background_co: hsla(0, 2%, 26%, 0.3);
	--gray_background_co: hsla(200, 6%, 29%, 0.3);
}

.prefers-reduced-motion {
	--transition-duration: 0ms;
}

@media (prefers-reduced-motion) {
	:root {
		--transition-duration: 0ms;
	}
}

.prefers-reduced-transparency {
	--transparency: 1000000;
}

@media (prefers-reduced-transparency) {
	:root {
		--transparency: 1000000;
	}
}

/**
 * Transition all changing colors
 * TODO: Some elements now have a delay.
 */
// * {
// 	transition-property: var(--foreground), var(--background);
// 	transition-duration: var(--transition-duration);
// 	transition-timing-function: ease-in-out;
// }
