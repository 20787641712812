.notion {
	$this: &;

	color: var(--foreground);
	caret-color: var(--foreground);
	font-family: var(--font-family);

	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //

	.notion-red_background {
		background-color: var(--red_background);
	}
	.notion-pink_background {
		background-color: var(--pink_background);
	}
	.notion-blue_background {
		background-color: var(--blue_background);
	}
	.notion-purple_background {
		background-color: var(--purple_background);
	}
	.notion-green_background {
		background-color: var(--green_background);
	}
	.notion-yellow_background {
		background-color: var(--yellow_background);
	}
	.notion-orange_background {
		background-color: var(--orange_background);
	}
	.notion-brown_background {
		background-color: var(--brown_background);
	}
	.notion-gray_background {
		background-color: var(--gray_background);
	}
	.notion-red_background_co {
		background-color: var(--red_background_co);
	}
	.notion-pink_background_co {
		background-color: var(--pink_background_co);
	}
	.notion-blue_background_co {
		background-color: var(--blue_background_co);
	}
	.notion-purple_background_co {
		background-color: var(--purple_background_co);
	}
	.notion-green_background_co {
		background-color: var(--green_background_co);
	}
	.notion-yellow_background_co {
		background-color: var(--yellow_background_co);
	}
	.notion-orange_background_co {
		background-color: var(--orange_background_co);
	}
	.notion-brown_background_co {
		background-color: var(--brown_background_co);
	}
	.notion-gray_background_co {
		background-color: var(--gray_background_co);
	}

	.notion-item-blue {
		background-color: var(--notion-item-blue);
	}
	.notion-item-orange {
		background-color: var(--notion-item-orange);
	}
	.notion-item-green {
		background-color: var(--notion-item-green);
	}
	.notion-item-pink {
		background-color: var(--notion-item-pink);
	}
	.notion-item-brown {
		background-color: var(--notion-item-brown);
	}
	.notion-item-red {
		background-color: var(--notion-item-red);
	}
	.notion-item-yellow {
		background-color: var(--notion-item-yellow);
	}
	.notion-item-default {
		background-color: var(--notion-item-default);
	}
	.notion-item-purple {
		background-color: var(--notion-item-purple);
	}
	.notion-item-gray {
		background-color: var(--notion-item-gray);
	}

	.notion b {
		font-weight: 600;
	}

	.notion-title {
		width: 100%;
		font-size: 2.5em;
		font-weight: 700;
		margin-block-end: 20px;
		line-height: 1.2;
	}

	.notion-h {
		position: relative;
		display: inline-block;

		font-weight: 600;
		line-height: 1.3;
		padding: 3px 2px;
		margin-block-end: 1px;

		max-width: 100%;
		white-space: pre-wrap;
		word-break: break-word;
	}

	.notion-h1 {
		font-size: 1.875em;
		margin-block-start: 1.08em;
	}

	.notion-title + .notion-h1,
	.notion-title + .notion-h2,
	.notion-title + .notion-h3 {
		margin-block-start: 0;
	}
	/* TODO: notion-page-content */
	.notion-h1:first-child {
		margin-block-start: 0;
	}
	/* .notion-h1:first-of-type {
  margin-block-start: 2px;
} */
	.notion-h2 {
		font-size: 1.5em;
		margin-block-start: 1.1em;
	}
	.notion-h3 {
		font-size: 1.25em;
		margin-block-start: 1em;
	}

	.notion-h:hover .notion-hash-link {
		opacity: 1;
	}

	.notion-hash-link {
		opacity: 0;
		text-decoration: none;
		float: left;
		margin-inline-start: -20px;
		padding-inline-end: 4px;
		fill: var(--foreground);
	}

	.notion-page {
		position: relative;
		padding: 0;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 0;
		align-items: flex-start;
		width: 100%;
		max-width: 100%;
	}

	.notion-full-page {
		padding-block-end: calc(max(10vh, 120px));
	}

	.notion-page-no-cover {
		margin-block-start: 48px !important;
		padding-block-start: 96px;
	}

	.notion-page-no-cover.notion-page-no-icon {
		padding-block-start: 0;
	}

	.notion-page-no-cover.notion-page-has-image-icon {
		padding-block-start: 148px;
	}

	.notion-page-has-cover.notion-page-no-icon {
		padding-block-start: 48px;
	}

	.notion-page-has-cover {
		padding-block-start: 112px;
	}

	.notion-page-has-cover.notion-page-has-text-icon {
		padding-block-start: 64px;
	}

	.notion-page-has-cover .notion-page-icon-wrapper img.notion-page-icon {
		top: -62px;
	}

	.notion-page-has-cover .notion-page-icon-wrapper span.notion-page-icon {
		top: -42px;
	}

	.notion-page {
		width: var(--container-max-width);
		padding-inline-start: calc(min(12px, 8vw));
		padding-inline-end: calc(min(12px, 8vw));
	}

	.notion-full-width {
		--container-max-width: calc(min(1920px, 98vw));
		padding-inline-start: calc(min(96px, 8vw));
		padding-inline-end: calc(min(96px, 8vw));
	}

	.notion-small-text {
		font-size: 14px;
	}

	.notion-link {
		color: inherit;
		word-break: break-word;
		text-decoration: inherit;
		border-block-end: 0.05em solid;
		border-color: var(--foreground-2);
		opacity: 0.7;
		transition: border-color 100ms ease-in, opacity 100ms ease-in;
	}

	.notion-link:hover {
		border-color: var(--foreground-6);
		opacity: 1;
	}

	.notion-collection .notion-link {
		opacity: 1;
	}

	.notion-blank {
		width: 100%;
		min-height: 1rem;
		padding: 3px 2px;
		margin-block-start: 1px;
		margin-block-end: 1px;
	}

	.notion-page-link {
		display: flex;
		color: var(--foreground);
		text-decoration: none;
		width: 100%;
		height: 30px;
		margin: 1px 0;
		transition: background 120ms ease-in 0s;
	}

	.notion-page-link:hover {
		background: var(--background-light);
	}

	.notion-page-icon {
		font-family: 'Apple Color Emoji', 'Segoe UI Emoji', NotoColorEmoji, 'Noto Color Emoji', 'Segoe UI Symbol',
			'Android Emoji', EmojiSymbols;
		font-size: 1.1em;
		margin: 2px 4px 0 2px;
		fill: var(--foreground-6);
		color: var(--foreground);
	}

	img.notion-page-icon,
	svg.notion-page-icon {
		display: block;
		object-fit: fill;
		border-radius: 3px;
		/* padding: 1px; */
		max-width: 22px;
		max-height: 22px;
	}

	.notion-icon {
		display: block;
		width: 18px;
		height: 18px;
		color: var(--foreground);
	}

	.notion-page-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
		line-height: 1.3;
		border-block-end: 1px solid var(--foreground-1);
		margin: 4px 0;
	}

	.notion-inline-code {
		color: #eb5757;
		padding: 0.2em 0.4em;
		background: var(--background-inline-code);
		border-radius: 3px;
		font-size: 85%;
		font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
	}

	.notion-inline-underscore {
		text-decoration: underline;
	}

	.notion-list {
		margin: 0;
		margin-block-start: 0.6em;
		margin-block-end: 0.6em;
	}

	.notion-list-disc {
		list-style-type: disc;
		padding-inline-start: 1.7em;
		margin-block-start: 0;
		margin-block-end: 0;
	}
	.notion-list-numbered {
		list-style-type: decimal;
		padding-inline-start: 1.6em;
		margin-block-start: 0;
		margin-block-end: 0;
	}

	.notion-list-disc li {
		padding-inline-start: 0.1em;
	}

	.notion-list-numbered li {
		padding-inline-start: 0.2em;
	}

	.notion-list li {
		padding: 6px 0;
		white-space: pre-wrap;
	}

	.notion-text {
		width: 100%;
		white-space: pre-wrap;
		word-break: break-word;
		padding: 3px 2px;
		margin: 1px 0;
	}

	.notion-text:first-child {
		margin-block-start: 2px;
	}

	.notion-text-children {
		padding-inline-start: 1.5em;
		display: flex;
		flex-direction: column;
	}

	.notion-block {
		padding: 3px 2px;
	}

	.notion .notion-code {
		font-size: 85%;
	}

	.notion-code {
		width: 100%;
		padding: 30px 16px 30px 20px;
		margin: 4px 0;
		border-radius: 3px;
		tab-size: 2;
		display: block;
		box-sizing: border-box;
		overflow: auto;
		background: var(--background-lighter);
		font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
	}

	.notion-column {
		display: flex;
		flex-direction: column;
		padding-block-start: 12px;
		padding-block-end: 12px;
	}

	.notion-column > *:first-child {
		margin-block-start: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
	}

	.notion-column > *:last-child {
		margin-inline-start: 0;
		margin-inline-end: 0;
		margin-block-end: 0;
	}

	.notion-row {
		display: flex;
		width: 100%;
		max-width: 100%;
	}

	@media (max-width: 640px) {
		.notion-row {
			flex-direction: column;
		}

		.notion-row .notion-column {
			width: 100% !important;
		}

		.notion-row .notion-spacer {
			display: none;
		}
	}

	.notion-bookmark {
		margin: 4px 0;
		width: 100%;
		box-sizing: border-box;
		text-decoration: none;
		border: 1px solid var(--foreground-1);
		border-radius: 3px;
		display: flex;
		overflow: hidden;
		user-select: none;
	}

	.notion-bookmark > div:first-child {
		flex: 4 1 180px;
		padding: 12px 14px 14px;
		overflow: hidden;
		text-align: left;
		color: var(--foreground);
	}

	.notion-bookmark-title {
		font-size: 14px;
		line-height: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: 24px;
		margin-block-end: 2px;
	}

	.notion-bookmark-description {
		font-size: 12px;
		line-height: 16px;
		opacity: 0.8;
		height: 32px;
		overflow: hidden;
	}

	.notion-bookmark-link {
		display: flex;
		margin-block-start: 6px;
	}

	.notion-bookmark-link > img {
		width: 16px;
		height: 16px;
		min-width: 16px;
		margin-inline-end: 6px;
	}

	.notion-bookmark-link > div {
		font-size: 12px;
		line-height: 16px;
		color: var(--foreground);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notion-bookmark-image {
		flex: 1 1 180px;
		position: relative;
	}

	.notion-bookmark-image img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.notion-column .notion-bookmark-image {
		display: none;
	}

	.notion-spacer {
		width: calc(min(32px, 4vw));
	}

	.notion-spacer:last-child {
		display: none;
	}

	.notion-image {
		display: block;
		width: 100%;
		border-radius: 1px;
	}

	.notion-toggle {
		padding: 3px 2px;
	}
	.notion-toggle > summary {
		cursor: pointer;
		outline: none;
	}
	.notion-toggle > div {
		margin-inline-start: 1.1em;
	}

	.notion-collection {
		align-self: center;
		min-width: 100%;
	}

	.notion-collection-header {
		display: flex;
		align-items: center;
		height: 42px;
		padding: 4px 2px;
		white-space: nowrap;
		overflow: hidden;
	}

	.notion-collection-header-title {
		display: inline-flex;
		align-items: center;
		font-size: 1.25em;
		line-height: 1.2;
		font-weight: 600;
		white-space: pre-wrap;
		word-break: break-word;
		margin-inline-end: 0.5em;
	}

	.notion-collection-view-dropdown {
		cursor: pointer;
		padding: 4px 8px;
		border-radius: 3px;
		transition: background 120ms ease-in 0s;
	}

	.notion-collection-view-dropdown:hover {
		background: var(--background-light);
	}

	.notion-collection-view-dropdown-icon {
		position: relative;
		top: 2px;
		margin-inline-start: 4px;
	}

	.notion-collection-view-type-menu-item {
		cursor: pointer;
	}

	.notion-collection-view-type-menu-item .notion-collection-view-type {
		width: 340px;
		max-width: 100%;
		min-width: 100px;
	}

	.notion-collection-view-type {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.notion-collection-view-type-icon {
		display: inline-block;
		width: 14px;
		height: 14px;
		/* fill: var(--foreground); */
		fill: rgba(55, 53, 47);
		margin-inline-end: 6px;
	}

	.notion-collection-view-type-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--foreground);
	}

	.notion-table-header {
		display: flex;
		position: absolute;
		z-index: 82;
		height: 33px;
		color: var(--foreground-3);
		min-width: var(--container-max-width);
	}

	.notion-table-header-inner {
		width: 100%;
		display: inline-flex;
		border-block-start: 1px solid var(--foreground-1);
		border-block-end: 1px solid var(--foreground-1);
		/* box-shadow: white -3px 0 0, rgba(55, 53, 47, 0.16) 0 1px 0; */
	}

	.notion-table-header-placeholder {
		height: 34px;
	}

	.notion-table-th {
		display: flex;
		position: relative;
	}

	.notion-table-view-header-cell {
		display: flex;
		flex-shrink: 0;
		overflow: hidden;
		height: 32px;
		font-size: 14px;
		padding: 0;
	}

	.notion-table-th:last-child .notion-table-view-header-cell-inner {
		border-inline-end: 0 none;
	}

	.notion-collection-column-title {
		display: flex;
		align-items: center;
		line-height: 120%;
		min-width: 0;
		font-size: 14px;
	}

	.notion-collection-column-title-icon {
		display: inline-block;
		width: 14px;
		height: 14px;
		min-width: 14px;
		min-height: 14px;
		fill: var(--foreground-2);
		margin-inline-end: 6px;
	}

	.notion-collection-column-title-body {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notion-table-body {
		position: relative;
		min-width: var(--container-max-width);
	}

	.notion-table-row {
		display: flex;
		border-block-end: 1px solid var(--foreground-1);
	}

	.notion-table-cell {
		min-height: 32px;
		padding: 5px 8px 6px;
		font-size: 14px;
		line-height: 1;
		white-space: normal;
		overflow: hidden;
		word-break: break-word;
		border-inline-end: 1px solid var(--foreground-1);
	}

	.notion-table-cell:last-child {
		border-inline-end: 0 none;
	}

	.notion-table-cell-title {
		font-weight: 500;
	}

	.notion-table-cell-text {
		white-space: pre-wrap;
	}

	.notion-table-cell-text,
	.notion-table-cell-number,
	.notion-table-cell-url,
	.notion-table-cell-email,
	.notion-table-cell-phone_number {
		line-height: 1.5;
	}

	.notion-table-cell-number {
		white-space: pre-wrap;
	}

	.notion-table-cell-select,
	.notion-table-cell-multi_select {
		padding: 7px 8px 0;
	}

	.notion-property-select,
	.notion-property-multi_select {
		display: flex;
		flex-wrap: wrap;
	}

	.notion-property-select-item,
	.notion-property-multi_select-item {
		display: flex;
		align-items: center;
		padding: 0 6px;
		border-radius: 3px;
		height: 18px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 120%;
	}

	.notion-property-multi_select-item {
		margin: 0 6px 6px 0;
	}

	.notion-collection-card .notion-property-multi_select-item {
		margin: 0 6px 0 0;
	}

	.notion-property-file {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.notion-property-file img {
		max-height: 24px;
		max-width: 100%;
		margin-inline-end: 6px;
	}

	.notion-collection-card-cover .notion-property-file {
		height: 100%;
	}

	.notion-collection-card-cover .notion-property-file img {
		width: 100%;
		margin: 0;
		max-height: 100%;
	}

	.notion-property-checkbox {
		width: 16px;
		height: 16px;
	}

	.notion-property-checkbox-checked {
		width: 16px;
		height: 16px;
		background: var(--select-color-0);
	}

	.notion-property-checkbox-checked svg {
		position: relative;
		display: block;
		/* top: -3px; */
		top: 1px;
		left: 1px;
		width: 14px;
		height: 14px;
		fill: #fff;
	}

	.notion-property-checkbox-unchecked {
		width: 16px;
		height: 16px;
		border: 1.3px solid var(--foreground);
	}

	.notion-gallery {
		align-self: center;
	}

	.notion-gallery-view {
		position: relative;
		padding-inline-start: 0;
		transition: padding 200ms ease-out;
	}

	.notion-gallery-grid {
		display: grid;
		position: relative;
		grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
		grid-auto-rows: 1fr;
		gap: 16px;
		border-block-start: 1px solid var(--foreground-1);
		padding-block-start: 16px;
		padding-block-end: 4px;
	}

	.notion-gallery-grid-size-small {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}

	.notion-gallery-grid-size-large {
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	}

	.notion-collection-card {
		display: flex;
		flex-direction: column;
		overflow: hidden;

		text-decoration: none;
		box-shadow: rgba(15, 15, 15, 0.1) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 2px 4px;
		border-radius: 3px;
		background: var(--background);
		color: var(--foreground);
		transition: background 100ms ease-out 0s;

		user-select: none;
		cursor: pointer;
	}

	.notion-collection-card:hover {
		background: var(--background-light);
	}

	.notion-collection-card-cover {
		position: relative;
		width: 100%;
		height: 190px;
		border-block-end: 1px solid var(--divider);
		overflow: hidden;
	}

	.notion-collection-card-cover img {
		width: 100%;
		height: 100%;
		border-radius: 1px 1px 0 0;
		/* object-fit: cover; */
	}

	.notion-collection-card-cover .notion-collection-card-cover-empty {
		width: 100%;
		height: 100%;
		pointer-events: none;
		overflow: hidden;
		background: var(--foreground-5);
		box-shadow: var(--divider) 0 -1px 0 0 inset;
		padding: 8px 8px 0;
	}

	.notion-collection-card-size-small .notion-collection-card-cover {
		height: 124px;
	}

	.notion-collection-card-body {
		display: flex;
		flex-direction: column;
		padding: 4px 10px;
	}

	.notion-collection-card-property {
		padding: 4px 0;
		white-space: nowrap;
		word-break: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
	}

	.notion-collection-card-property:first-child {
		font-size: 14px;
		font-weight: 500;
	}

	.notion-collection-card-property:not(:first-child) {
		white-space: nowrap;
		text-overflow: clip;
	}

	.notion-collection-card-property img {
		max-height: 18px;
	}

	.notion-list-collection {
		align-self: center;
	}

	.notion-list-collection {
		width: 100%;
		max-width: 100%;
	}

	.notion-list-view {
		position: relative;
		padding-inline-start: 0;
		transition: padding 200ms ease-out;
		max-width: 100%;
	}

	.notion-list-body {
		display: flex;
		flex-direction: column;
		border-block-start: 1px solid var(--foreground-1);
		padding-block-start: 8px;
		max-width: 100%;
		overflow: hidden;
	}

	.notion-list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 4px;
		margin: 1px 0;
		max-width: 100%;
		overflow: hidden;
	}

	.notion-list-item-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
		line-height: 1.3;
	}

	.notion-list-item-body {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		overflow: hidden;
	}

	.notion-list-item-property {
		/* display: flex;
  align-items: center; */
		margin-inline-start: 14px;
		font-size: 14px;
	}

	.notion-list-item-property .notion-property-date,
	.notion-list-item-property .notion-property-created_time,
	.notion-list-item-property .notion-property-last_edited_time,
	.notion-list-item-property .notion-property-url {
		display: inline-block;
		color: var(--foreground-3);
		font-size: 12px;
		/* white-space: nowrap; */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notion-board {
		width: 100vw;
		max-width: 100vw;
		align-self: center;
		overflow: auto hidden;
	}

	.notion-board-view {
		position: relative;
		float: left;
		min-width: 100%;
		padding-inline-start: 0;
		transition: padding 200ms ease-out;
	}

	.notion-board-header {
		display: flex;
		position: absolute;
		z-index: 82;
		height: 44px;
		min-width: 100%;
	}

	.notion-board-header-inner {
		display: inline-flex;
		border-block-start: 1px solid var(--foreground-1);
		border-block-end: 1px solid var(--foreground-1);
	}

	.notion-board-header-placeholder {
		height: var(--header-height);
	}

	.notion-board-th {
		display: flex;
		align-items: center;
		font-size: 14px;
		padding-inline-end: 16px;
		box-sizing: content-box;
		flex-shrink: 0;
	}

	.notion-board-th-body {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 1.2;
		padding-inline-start: 2px;
		padding-inline-end: 4px;
		white-space: nowrap;
		overflow: hidden;
	}

	.notion-board-th-count {
		color: var(--foreground-3);
		font-weight: 500;
		padding: 0 8px;
	}

	.notion-board-th-empty {
		margin-inline-end: 4px;
		position: relative;
		top: 2px;
	}

	.notion-board-body {
		display: inline-flex;
	}

	.notion-board-group {
		flex: 0 0 auto;
		padding-inline-end: 16px;
		box-sizing: content-box;
	}

	.notion-board-group-card {
		margin-block-end: 8px;
	}

	.notion-board-view .notion-board-th,
	.notion-board-view .notion-board-group {
		width: 260px;
	}

	.notion-board-view-size-small .notion-board-th,
	.notion-board-view-size-small .notion-board-group {
		width: 180px;
	}

	.notion-board-view-size-large .notion-board-th,
	.notion-board-view-size-large .notion-board-group {
		width: 320px;
	}

	.notion-board-view .notion-collection-card .notion-collection-card-cover {
		height: 148px;
	}

	.notion-board-view-size-small .notion-collection-card .notion-collection-card-cover {
		height: 100px;
	}

	.notion-board-view-size-large .notion-collection-card .notion-collection-card-cover {
		height: 180px;
	}

	.notion-to-do {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.notion-to-do-item {
		width: 100%;
		display: flex;
		align-items: center;
		width: 100%;
		padding-inline-start: 2px;
		min-height: calc(1.5em + 3px + 3px);
	}

	.notion-to-do-children {
		padding-inline-start: 1.5em;
	}

	.notion-to-do-checked .notion-to-do-item {
		text-decoration: line-through;
		opacity: 0.375;
	}

	.notion-to-do-body {
		white-space: pre-wrap;
		word-break: break-word;
	}

	.notion-to-do-item .notion-property-checkbox {
		margin-inline-end: 8px;
	}

	.notion-google-drive {
		width: 100%;
		align-self: center;
		margin: 4px 0;
	}

	.notion-google-drive-link {
		position: relative;
		display: flex;
		flex-direction: column;
		color: inherit;
		text-decoration: none;
		width: 100%;
		border: 1px solid var(--foreground-1);
		border-radius: 3px;

		user-select: none;
		transition: background 20ms ease-in 0s;
		cursor: pointer;
	}

	.notion-google-drive-link:hover {
		background: var(--background-light);
	}

	.notion-google-drive-preview {
		display: block;
		position: relative;
		width: 100%;
		padding-block-end: 55%;
		overflow: hidden;
	}

	.notion-google-drive-preview img {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		object-fit: cover;
		object-position: center top;
	}

	.notion-google-drive-body {
		width: 100%;
		min-height: 60px;
		padding: 12px 14px 14px;
		overflow: hidden;
		border-block-start: 1px solid var(--foreground-1);
	}

	.notion-google-drive-body-title {
		font-size: 14px;
		line-height: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-block-end: 2px;
	}

	.notion-google-drive-body-modified-time {
		font-size: 12px;
		line-height: 1.3;
		color: var(--foreground-3);
		max-height: 32px;
		overflow: hidden;
	}

	.notion-google-drive-body-source {
		display: flex;
		align-items: center;
		margin-block-start: 6px;
	}

	.notion-google-drive-body-source-icon {
		flex-shrink: 0;
		background-size: cover;
		width: 16px;
		height: 16px;
		margin-inline-end: 6px;
	}

	.notion-google-drive-body-source-domain {
		font-size: 12px;
		line-height: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notion-file {
		width: 100%;
		margin: 1px 0;
	}

	.notion-file-link {
		display: flex;
		align-items: center;
		padding: 3px 2px;
		border-radius: 3px;
		transition: background 20ms ease-in 0s;
		color: inherit;
		text-decoration: none;
	}

	.notion-file-link:hover {
		background: var(--background-light);
	}

	.notion-file-icon {
		margin-inline-end: 2px;
		width: 1.35em;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 0;
		flex-shrink: 0;
		min-height: calc(1.5em + 3px + 3px);
		height: 1.35em;
	}

	.notion-file-info {
		display: flex;
		align-items: baseline;
	}

	.notion-file-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notion-file-size {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--foreground-3);
		font-size: 12px;
		line-height: 16px;
		margin-inline-start: 6px;
	}

	.notion-audio {
		width: 100%;
	}

	.notion-audio audio {
		width: 100%;
	}

	.notion-page-title {
		display: inline-flex;
		max-width: 100%;
		align-items: center;
		line-height: 1.3;
		transition: background 120ms ease-in 0s;
	}

	.notion-page-title-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 22px;
		width: 22px;
		border-radius: 3px;
		flex-shrink: 0;
		margin-inline-start: 2px;
		margin-inline-end: 6px;
	}

	.notion-collection-card-property .notion-link {
		border-block-end: 0 none;
	}

	.notion-collection-card-property .notion-page-title {
		transition: none;
	}

	.notion-collection-card-property .notion-page-title:hover {
		background: unset;
	}

	.notion-collection-card-property .notion-page-title-icon {
		margin-inline-start: 0;
		height: 18px;
		width: 18px;
	}

	.notion-collection-card-property .notion-page-title-text {
		border-block-end: 0 none;
	}
	.notion-link .notion-page-title-text {
		border-block-end: 0 none;
	}

	.notion-collection-card-property .notion-property-relation .notion-page-title-text {
		border-block-end: 1px solid;
	}

	.notion-page-title-text {
		position: relative;
		top: 1px;
		border-block-end: 1px solid var(--foreground-1);
		line-height: 1.3;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
	}

	.notion-collection-row {
		width: 100%;
		padding: 4px 0 8px;
		border-block-end: 1px solid var(--divider);
		margin-block-end: 1em;
	}

	.notion-collection-row-body {
		display: flex;
		flex-direction: column;
	}

	.notion-collection-row-property {
		display: flex;
		align-items: center;
		margin-block-end: 4px;
	}

	.notion-collection-row-value {
		flex: 1;
		padding: 6px 8px 7px;
		font-size: 14px;
	}

	.notion-collection-row-property .notion-collection-column-title {
		display: flex;
		align-items: center;
		width: 160px;
		height: 34px;
		color: var(--foreground-3);
		padding: 0 6px;
	}

	.notion-collection-row-property .notion-property {
		width: 100%;
	}

	.notion-collection-row-property .notion-collection-column-title-icon {
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
	}

	.notion-collection-row-property .notion-link {
		border-block-end: 0 none;
	}

	.notion-collection-row-property .notion-property-relation .notion-page-title-text {
		border-block-end: 1px solid;
	}

	.notion-user {
		display: block;
		object-fit: cover;
		border-radius: 100%;
		width: 20px;
		height: 20px;
	}

	.notion-list-item-property .notion-property-multi_select-item {
		margin-block-end: 0;
		flex-wrap: none;
	}

	.notion-list-item-property .notion-property-multi_select-item:last-of-type {
		margin-inline-end: 0;
	}

	.notion-toggle .notion-collection-header,
	.notion-toggle .notion-table-view,
	.notion-toggle .notion-board-view,
	.notion-column .notion-collection-header,
	.notion-column .notion-table-view,
	.notion-column .notion-board-view {
		padding-inline-start: 0 !important;
		padding-inline-end: 0 !important;
	}

	.notion-toggle .notion-table,
	.notion-toggle .notion-board,
	.notion-column .notion-table,
	.notion-column .notion-board {
		width: 100% !important;
		max-width: 100% !important;
	}

	@media (max-width: 640px) {
		.notion-bookmark-image {
			display: none;
		}
	}

	.notion-lite {
		overflow-y: auto;
	}

	.notion-lite .notion-page {
		width: 100%;
		padding: 0;
		/* padding: calc(max(2vmin, 8px)); */
	}

	.notion-lite .notion-collection-header,
	.notion-lite .notion-table-view,
	.notion-lite .notion-board-view {
		padding-inline-start: 0 !important;
		padding-inline-end: 0 !important;
	}

	.notion-lite .notion-board,
	.notion-lite .notion-table {
		width: 100% !important;
	}

	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //
	// --------------------------------------------------------------------------------------------- //

	* {
		margin-block-start: 0;
		margin-block-end: 0;

		&::selection {
			background: var(--select-color-1);
		}

		&:focus-visible {
			outline: max(2px, 0.1em) dashed currentColor;
		}

		&:focus:not(:focus-visible) {
			outline: none;
		}
	}

	&-gray,
	&-gray_co {
		color: var(--gray-foreground);
	}
	&-brown,
	&-brown_co {
		color: var(--brown-foreground);
	}
	&-orange,
	&-orange_co {
		color: var(--orange-foreground);
	}
	&-yellow,
	&-yellow_co {
		color: var(--yellow-foreground);
	}
	&-green,
	&-green_co {
		color: var(--green-foreground);
	}
	&-purple,
	&-purple_co {
		color: var(--purple-foreground);
	}
	&-blue,
	&-blue_co {
		color: var(--blue-foreground);
	}
	&-pink,
	&-pink_co {
		color: var(--pink-foreground);
	}
	&-red,
	&-red_co {
		color: var(--red-foreground);
	}

	&-viewport {
		position: fixed;
		inset: 0;
	}

	&-app {
		position: relative;
		background: var(--background);
		min-height: 100vh;
	}

	&-page {
		&-icon-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.notion-page-icon {
				position: relative;
				display: block;
			}

			span.notion-page-icon {
				height: 78px;
				width: 78px;
				max-width: 78px;
				max-height: 78px;
				font-size: 78px;
				line-height: 1.1;
				margin-inline-start: 0;
				color: var(--foreground);
			}

			img.notion-page-icon {
				display: block;
				border-radius: 3px;
				width: 124px;
				height: 124px;
				max-width: 124px;
				max-height: 124px;
			}
		}

		&-cover {
			display: block;
			object-fit: cover;
			width: 100%;
			--page-cover-height: calc(100vh / 3);
			height: var(--page-cover-height);
			min-height: var(--page-cover-height);
			max-height: var(--page-cover-height);
			padding: 0;

			&.lazy-image {
				&-wrapper {
					padding: 0 !important;
				}
			}

			.lazy-image {
				&-preview,
				&-real {
					will-change: unset !important;
				}

				&-loaded {
					.lazy-image {
						&-preview {
							opacity: 1;
						}
					}
				}
			}
		}

		&-scroller {
			position: relative;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			align-items: center;
			margin-block-start: calc(var(--header-height) * -1);
			min-height: 100vh;
		}

		&-content {
			width: 100%;
			display: flex;
			flex-direction: column;

			&-inner {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	&-aside {
		position: sticky;
		top: 148px;

		align-self: flex-start;
		flex: 1;

		display: none;
		flex-direction: column;
		align-items: center;

		&-table-of-contents {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-height: calc(100vh - 148px - 16px);
			overflow: hidden auto;
			overflow: auto;
			min-width: 222px;

			&-header {
				text-transform: uppercase;
				font-weight: 400;
				font-size: 1.1em;
				word-break: break-word;
			}

			#{$this} {
				&-table-of-contents {
					&-item {
						line-height: 1;

						&-body {
							border: 0 none;
						}

						&-indent {
							&-level-0 {
								margin-block-start: 0.25em;

								&:first-of-type {
									margin-block-start: 0;
								}
							}

							&-level-1 {
								font-size: 13px;
							}

							&-level-2 {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	&-table-of-contents {
		width: 100%;
		margin: 4px 0;

		&-item {
			color: inherit;
			text-decoration: none;
			user-select: none;
			transition: background 20ms ease-in 0s;
			cursor: pointer;
			width: 100%;

			padding: 6px 2px;
			font-size: 14px;
			line-height: 1.3;
			display: flex;
			align-items: center;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				background: var(--background-light);
			}

			&-body {
				border-block-end: 1px solid var(--foreground-1);
			}
		}

		&-active-item {
			color: var(--select-color-0) !important;
		}
	}

	&-collection {
		&-card {
			&-cover {
				.lazy-image {
					&-wrapper {
						padding: 0 !important;
						height: 100%;
					}
				}
			}
		}
	}

	&-frame {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		.katex-display .katex {
			padding-inline-end: 32px;
		}

		.katex > .katex-html {
			white-space: normal;
		}
	}

	&-hr {
		width: 100%;
		margin: 6px 0;
		padding: 0;
		border-block-start: none;
		border-color: var(--divider);
	}

	&-quote {
		display: block;
		width: 100%;
		white-space: pre-wrap;
		word-break: break-word;
		border-inline-start: 3px solid currentcolor;
		padding: 0.2em 0.9em;
		margin: 6px 0;
		font-size: 1.2em;
	}

	&-callout {
		padding: 16px 16px 16px 12px;
		display: inline-flex;
		width: 100%;
		border-radius: 3px;
		border-width: 1px;
		align-items: center;
		box-sizing: border-box;
		margin: 4px 0;
		background: var(--background-callout);

		&-text {
			margin-inline-start: 8px;
			white-space: pre-wrap;
			word-break: break-word;
		}

		> #{$this}-page-icon {
			align-self: flex-start;
			width: 24px;
			height: 24px;
			font-size: 1.3em;
			line-height: 1em;
		}
	}

	&-table {
		width: 100vw;
		max-width: 100vw;
		align-self: center;
		overflow: auto hidden;

		&-view {
			position: relative;
			float: left;
			min-width: var(--container-max-width);
			padding-inline-start: 0;
			transition: padding 200ms ease-out;

			&-header {
				&-cell {
					&-inner {
						user-select: none;
						display: flex;
						width: 100%;
						height: 100%;
						padding-inline-start: 8px;
						padding-inline-end: 8px;
						border-inline-end: 1px solid var(--table-divider);
					}
				}
			}
		}
	}

	&-equation {
		position: relative;
		display: inline-flex;
		color: inherit;
		fill: inherit;
		user-select: none;
		border-radius: 3px;
		// transition: background 20ms ease-in 0s;

		&-inline {
			user-select: all;
		}

		&-block {
			display: flex;
			flex-direction: column;
			overflow: auto;
			width: 100%;
			max-width: 100%;
			padding: 4px 8px;
			margin: 4px 0;
			cursor: pointer;
		}

		// &:hover {
		// 	background: var(--background-light);
		// }

		// &:active,
		// &:focus {
		// 	background: var(--select-color-2);
		// }
	}

	&-asset {
		&-wrapper {
			margin: 0.5rem 0;
			max-width: 100vw;
			min-width: 100%;
			align-self: center;
			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover !important;
			}

			iframe {
				border: none;
				background: rgb(247, 246, 245); // TODO: CSS VAR
			}

			&-image {
				max-width: 100%;
			}

			&-full {
				max-width: 100vw;
				width: 100vw;
			}
		}

		&-object-fit {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			border-radius: 1px;
		}

		&-caption {
			padding: 6px 0 6px 2px;
			white-space: pre-wrap;
			word-break: break-word;
			caret-color: var(--foreground);
			font-size: 14px;
			line-height: 1.4;
			color: var(--foreground-3);
		}
	}

	&-header {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 200;

		width: 100%;
		max-width: 100vw;
		overflow: hidden;
		height: var(--header-height);
		min-height: var(--header-height);

		background: var(--background-header);
		box-shadow: var(--header-box-shadow);
		backdrop-filter: saturate(180%) blur(8px);

		&-anchor {
			position: absolute;
			top: calc(var(--header-height) * -1);
			left: 0;
		}

		.nav-header {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			text-size-adjust: 100%;
			line-height: 1.5;
			line-height: 1.2;
			font-size: 14px;
		}

		.breadcrumbs {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 100%;
			flex-grow: 0;
			min-width: 0;
			margin-inline-end: 1rem;
		}

		.breadcrumb {
			display: inline-flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			white-space: nowrap;
			text-overflow: ellipsis;

			color: var(--foreground);
			text-decoration: none;
			margin: 1px 0px;
			padding: 4px 6px;
			border-radius: 3px;
			transition-duration: calc(var(--transition-duration) / 2);
			transition-property: backdrop-filter;
			transition-timing-function: var(--transition-timing-function);
			user-select: none;
			background: transparent;
			cursor: pointer;

			&:not(.active):hover {
				background: var(--background-light);
			}

			&:not(.active):active {
				background: var(--background-lighter);
			}

			&.active {
				cursor: default;
			}

			.icon {
				position: relative;
				top: -1px;
			}
		}

		img.icon {
			width: 18px !important;
			height: 18px !important;
		}

		.icon {
			font-size: 18px;
			margin-inline-end: 6px;
			line-height: 1.1;
		}

		.spacer {
			margin: 0 2px;
			color: var(--foreground-2);
		}

		.button {
			height: 28px;
			padding: 0 8px;
		}
	}

	@media only screen and (max-width: 730px) {
		&-page {
			padding-inline-start: 2vw;
			padding-inline-end: 2vw;
		}

		&-asset {
			&-wrapper {
				max-width: 100%;

				&-full {
					max-width: 100vw;
					width: 100vw;
				}
			}
		}
	}

	@media (min-width: 1300px) and (min-height: 300px) {
		&-page {
			&-content {
				&-has-aside {
					display: flex;
					flex-direction: row;
					width: calc((100vw + var(--container-max-width)) / 2);

					#{$this} {
						&-aside {
							display: flex;
						}

						&-page {
							&-content {
								&-inner {
									width: var(--container-max-width);
									max-width: var(--container-max-width);
								}
							}
						}
					}
				}
			}
		}
	}
}
