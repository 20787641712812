html,
body {
	font-family: var(--font-family);
	background: var(--background);
	color: var(--foreground);
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
	line-height: 1.625;
	overflow-x: hidden;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/**
 * To fill the Vue component to it's React parent, this is required.
 * If no wrapper is added to the Vue component, React cannot diff it.
 */
[class^='react_wrapper_'] {
	width: 100%;
	height: 100%;
}
